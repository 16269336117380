import React from 'react';

import { AITool } from '@vyce/core/src/views/ai';

import { useTypedSelector } from '../../hooks';

export const EmployerAITool: React.FC = () => {
  const { user } = useTypedSelector(state => state);
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <AITool user={user} companyId={selectedCompany?.uuid} />;
};
