import React, { useContext } from 'react';

import { Box, Button } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import noop from 'lodash/noop';

import { ActivateSubscriptionDialog, FilterSystem } from '@vyce/core/src/components';
import { useLocationUsersData } from '@vyce/core/src/modules/timeModule/hooks';
import { LocationUsers } from '@vyce/core/src/views/time/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppSearchInput } from '@vyce/core/src/components/inputs';

import { UniversalInviteModule } from '../../modules';
import { useInviteButton, useTypedSelector } from '../../hooks';

export const LocationsUsers: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  const {
    workers,
    total,
    sortModel,
    loadingTableData,
    filters,
    filtersSections,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
    handleSortModelChange,
    setOffset,
    removeWorkerFromAllLocations,
  } = useLocationUsersData({ siteId: undefined, selectedCompany });

  const {
    isActivateSubscriptionDialogOpen,
    isInviteFormOpen,
    subscribeLoading,
    closeActivateSubscriptionDialog,
    setCloseInviteForm,
    handleInviteButtonClick,
    simpleSubscribe,
  } = useInviteButton();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={3}>
        <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />

        <Box display="flex" alignItems="center" gridGap={16}>
          <Button
            onClick={handleInviteButtonClick}
            size="small"
            data-tour="invite"
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Invite New Members
          </Button>
          {filtersSections && (
            <FilterSystem
              selectedValues={filters}
              filtersSections={filtersSections}
              onFiltersChange={handleFilterChange || noop}
            />
          )}
        </Box>
      </Box>

      <LocationUsers
        loading={loadingTableData}
        total={total}
        sortModel={sortModel}
        tableHeight="calc(100vh - 270px)"
        handleSortModelChange={handleSortModelChange}
        handlePageChange={handlePageChange}
        workers={workers}
        setOffset={setOffset}
        removeWorkerFromAllLocations={removeWorkerFromAllLocations}
      />

      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />

      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </>
  );
};
