import { useState, useContext, useEffect, useRef } from 'react';

import { Box, Button, Popover, Typography, useTheme, makeStyles } from '@material-ui/core';
import { IoCloseOutline } from 'react-icons/io5';

import { getBackground } from '@vyce/core/src/theme/styles';
import { Message } from '@vyce/core/src/views/ai/components/Message';
import { AskAIInput } from '@vyce/core/src/views/ai/components/AskAIInput';
import { AIChatContext } from '@vyce/core/src/views/ai/context';
import { AIChatMessage } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import VyceLoader from '@vyce/core/src/components/VyceLoader';

interface Props {
  assistantId: string;
}

export const ChatBot = ({ assistantId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { createThread, messages, setMessages, deleteThread, threadId, askAiAssistant, loading } =
    useContext(AIChatContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useContext(DeviceContext);

  const theme = useTheme();
  const height = isMobile ? '90vh' : '45vh';
  const width = isMobile ? '100%' : '450px';
  const background = getBackground(theme.palette.type === 'dark');
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const messages: AIChatMessage[] = [
      {
        role: 'assistant',
        content: 'Hello! I am **Laila**, your AI assistant. How can I help you today?',
      },
    ];
    createThread(messages);
    setMessages(messages);

    return () => {
      if (threadId) {
        deleteThread(threadId);
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.role === 'user' && threadId) {
      askAiAssistant({ message: lastMessage, assistantId, threadId });
    }
  }, [messages, threadId]);

  return (
    <Box>
      <Button
        style={{ visibility: open ? 'hidden' : 'visible' }}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}>
        Ask our Chatbot
      </Button>

      <Popover
        classes={{ root: classes.root, paper: classes.paper }}
        id={id}
        open={open}
        style={{ zIndex: 1 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        BackdropProps={{ invisible: true, open: false }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <Box
          maxWidth={width}
          minHeight={height}
          maxHeight={height}
          overflow="hidden"
          display="flex"
          flexDirection="column">
          <Box
            padding={2}
            height={'100%'}
            paddingBottom={1}
            paddingTop={1}
            bgcolor={background.backgroundInput}
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Typography variant="subtitle1" color="textSecondary">
              Ask our Chatbot
            </Typography>

            <Box onClick={handleClose}>
              <IoCloseOutline size="20px" color={theme.palette.text.secondary} />
            </Box>
          </Box>

          {loading && (
            <Box
              width="100%"
              position="absolute"
              display="flex"
              zIndex={0}
              alignItems="center"
              justifyContent="center"
              height="100%">
              <VyceLoader />
            </Box>
          )}

          <Box
            overflow="auto"
            zIndex={1}
            flex={1}
            display="flex"
            flexDirection="column"
            gridGap={8}
            padding={2}>
            {messages.map((message, index) => (
              <Message
                copyToClipboard={false}
                bgColor={
                  message.role === 'assistant'
                    ? theme.palette.background.default
                    : theme.palette.primary.main
                }
                key={message.content + index}
                message={message}
              />
            ))}
            <div ref={messagesEndRef} />
          </Box>

          <AskAIInput attachFile={false} placeholder="Type here..." style={{ padding: 16 }} />
        </Box>
      </Popover>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: '10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
  },
  root: {
    borderRadius: 10,
    zIndex: 0,
  },
  paper: {
    position: 'fixed',
  },
  inputContainer: {
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  },
}));
